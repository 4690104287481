import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { BusinessAdvantagesSection } from 'views/vue-js/business-advantages-section';
import { CodeConsultingSection } from 'views/vue-js/code-consulting-section';
import {
    LATEST_RELATED_ARTICLES_SETTINGS,
    PERKS_LIST,
} from 'views/vue-js/constants';
import { ContactWithExpertSection } from 'views/vue-js/contact-with-expert-section';
import { FamousAppsSection } from 'views/vue-js/famous-apps-section';
import { HireDevelopersSection } from 'views/vue-js/hire-developers-section';
import { ObjectiveAdvantagesSection } from 'views/vue-js/objective-advantages-section';
import { CONSTANTS } from 'constants/styles/constants';
import { FooterSection } from 'modules/footer-section';
import { WorkWithMRSection } from 'modules/technologies/work-with-mr-section';
import { array } from 'prop-types';

const Container = styled.div`
    padding-top: ${CONSTANTS.MARGIN.PAGE};
`;

const Props = {
    faqData: array.isRequired,
};

export const VueJsView = ({ faqData }) => {
    const { formatMessage } = useIntl();

    return (
        <Container>
            <CodeConsultingSection />
            <FamousAppsSection />
            <ObjectiveAdvantagesSection />
            <HireDevelopersSection />
            <BusinessAdvantagesSection />
            <WorkWithMRSection
                title="vue-js.workWithMobileReality.title"
                firstRowDescriptions={[
                    'vue-js.workWithMobileReality.atMobileReality',
                    'vue-js.workWithMobileReality.agileDevelopment',
                ]}
                secondRowDescriptions={[
                    'vue-js.workWithMobileReality.collaborativeApproach',
                ]}
                perksData={PERKS_LIST}
                firstRowAlt={formatMessage({
                    id: 'vue-js.workWithMobileReality.firstRowAlt',
                })}
                secondRowAlt={formatMessage({
                    id: 'vue-js.workWithMobileReality.secondRowAlt',
                })}
            />
            <ContactWithExpertSection />
            <FooterSection
                faqSection={{
                    title: faqData.title,
                    data: faqData.data,
                    parseRawAnswer: true,
                }}
                latestRelatedArticles={LATEST_RELATED_ARTICLES_SETTINGS}
            />
        </Container>
    );
};

VueJsView.propTypes = Props;
