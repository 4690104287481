import React from 'react';
import GitLabLogo from 'svgs/vue-js-apps/gitlab-logo.svg';
import GlovoLogo from 'svgs/vue-js-apps/glovo-logo.svg';
import GrammarlyLogo from 'svgs/vue-js-apps/grammarly-logo.svg';
import TrivagoLogo from 'svgs/vue-js-apps/trivago-logo.svg';
import WizzLogo from 'svgs/vue-js-apps/wizz-logo.svg';

export const VUE_FAMOUS_APPS = [
    {
        logo: <GlovoLogo />,
    },
    {
        logo: <GrammarlyLogo />,
    },
    {
        logo: <WizzLogo />,
    },
    {
        logo: <GitLabLogo />,
    },
    {
        logo: <TrivagoLogo />,
    },
];
