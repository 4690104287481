export const VUE_JS_ADVANTAGES = [
    {
        title: 'vue-js.theFlexibilityOfApplication',
        description: 'vue-js.whenWorkingWithVue',
    },
    {
        title: 'vue-js.smallSize',
        description: 'vue-js.highPerformance',
    },
    {
        title: 'vue-js.scaling',
        description: 'vue-js.vueJsCanCreate',
    },
];
