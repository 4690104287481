import React from 'react';
import { BoxElement } from 'components/box-element';
import { Container } from 'components/container';
import { gaTrackedEvents } from 'constants/ga-tracked-events';

export const HireDevelopersSection = () => {
    return (
        <Container>
            <BoxElement
                title="vue-js.hireVueJsDevelopers"
                googleAnalytics={gaTrackedEvents.VUE_JS.CTA.BOOK_CONSULTATION}
            />
        </Container>
    );
};
