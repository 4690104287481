import React from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'components/container';
import { EXTERNAL_REDIRECTS } from 'constants/external-redirects';
import { graphql, useStaticQuery } from 'gatsby';
import { ContactWithExpert } from 'modules/contact-with-expert';

export const ContactWithExpertSection = () => {
    const { formatMessage } = useIntl();
    const images = useStaticQuery(graphql`
        {
            magda: file(relativePath: { eq: "team/magda-expert.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 242
                        placeholder: NONE
                        layout: CONSTRAINED
                        quality: 100
                    )
                }
            }
        }
    `);

    return (
        <Container>
            <ContactWithExpert
                photo={images.magda.childImageSharp}
                photoWidth={242}
                expertRole={formatMessage({ id: 'vue-js.expertRoleAlt' })}
                skype={EXTERNAL_REDIRECTS.SKYPE_CALL_URL}
            />
        </Container>
    );
};
