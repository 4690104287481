import React from 'react';
import { BLOG_POST_TAGS } from 'constants/blog-post-tags';
import { PATHS } from 'constants/paths';
import JavascriptSvg from 'svgs/blog/javascript.svg';
import CertifiedIcon from 'svgs/technologies/work-with-mr/award.svg';
import MarketIcon from 'svgs/technologies/work-with-mr/calendar-tick.svg';
import MaintainersIcon from 'svgs/technologies/work-with-mr/code.svg';
import ClientsIcon from 'svgs/technologies/work-with-mr/people.svg';

export const VUE_JS_BUSINESS_ADVANTAGES = [
    {
        title: 'vue-js.creatingSinglePage',
        description: 'vue-js.developmentOfOnline',
    },
    {
        title: 'vue-js.integration',
        description: 'vue-js.ourTeam',
    },
    {
        title: 'vue-js.creatingWebApplications',
        description: 'vue-js.webApplicationDevelopment',
    },
    {
        title: 'vue-js.supportAndConsultingServices',
        description: 'vue-js.ourExpertsInVue',
    },
];

export const PERKS_LIST = [
    {
        title: 'vue-js.workWithMobileReality.onTheMarket',
        icon: <MarketIcon />,
    },
    {
        title: 'vue-js.workWithMobileReality.certifiedDevelopers',
        icon: <CertifiedIcon />,
    },
    {
        title: 'vue-js.workWithMobileReality.ownersAndMaintainers',
        icon: <MaintainersIcon />,
    },
    {
        title: 'vue-js.workWithMobileReality.experiencedInWorking',
        icon: <ClientsIcon />,
    },
];

export const LATEST_RELATED_ARTICLES_SETTINGS = {
    hideTitle: true,
    showAllArticles: true,
    tags: [BLOG_POST_TAGS.VUE_JS],
    button: {
        to: `${PATHS.BLOG}/${PATHS.JAVASCRIPT}`,
        label: 'vue-js.footer.button',
    },
    categoryBanner: {
        title: 'vue-js.footer.title',
        description: 'vue-js.footer.description',
        svg: JavascriptSvg,
    },
};
