import React from 'react';
import { VUE_JS_ADVANTAGES } from 'views/vue-js/objective-advantages-section/constants';
import { AdvantagesSectionTemplate } from 'components/advantages-section-template';
import { Container } from 'components/container';

export const ObjectiveAdvantagesSection = () => {
    return (
        <Container>
            <AdvantagesSectionTemplate
                title="vue-js.objectiveAdvantagesOfVueJs"
                firstParagraph="vue-js.theAdvantagesOfDeveloping"
                secondParagraph="vue-js.oneOfTheMainAdvantages"
                advantages={VUE_JS_ADVANTAGES}
            />
        </Container>
    );
};
