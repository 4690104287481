import React from 'react';
import { VUE_JS_BUSINESS_ADVANTAGES } from 'views/vue-js/constants';
import { AdvantagesSectionTemplate } from 'components/advantages-section-template';
import { Container } from 'components/container';

export const BusinessAdvantagesSection = () => {
    return (
        <Container>
            <AdvantagesSectionTemplate
                title="vue-js.whatWeCanGive"
                advantages={VUE_JS_BUSINESS_ADVANTAGES}
            />
        </Container>
    );
};
