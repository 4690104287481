import React from 'react';
import { MainSectionTemplate } from 'components/main-section-template';
import { gaTrackedEvents } from 'constants/ga-tracked-events';

export const CodeConsultingSection = () => {
    return (
        <MainSectionTemplate
            title="vue-js.vueJsCodeConsulting"
            subTitle="vue-js.asTheLeaderInVue"
            firstParagraph="vue-js.vueJsIsAPowerful"
            googleAnalytics={gaTrackedEvents.VUE_JS.CTA.ESTIMATE_PROJECT}
        />
    );
};
