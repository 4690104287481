import React from 'react';
import { VUE_FAMOUS_APPS } from 'views/vue-js/famous-apps-section/constants';
import { CardSectionTemplate } from 'components/card-section-template';
import { Container } from 'components/container';

export const FamousAppsSection = () => {
    return (
        <Container>
            <CardSectionTemplate
                title="vue-js.famousAppsBuiltUsingVueJs"
                results={VUE_FAMOUS_APPS}
            />
        </Container>
    );
};
